import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';

import useStyles from './NotFoundPage.styles';

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <motion.div
        className={classes.image}
        animate={{ rotateZ: 2 }}
        transition={{ duration: 1, repeat: Infinity, repeatType: 'mirror' }}
      >
        <Image
          alt=""
          src="https://korekuta.ru/static/app/shrug.png"
          width={554}
          height={439}
          priority
        />
      </motion.div>
      <div className={classes.body}>
        <h1 className={classes.text}>Page was not found!</h1>
        <h3 className={classes.text}>¯\_(ツ)_/¯</h3>
        <Link className={classes.link} href="/">
          Go Back to Korekuta
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
