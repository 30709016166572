import { createUseStyles } from 'react-jss';

import theme from '../../../theme';

export default createUseStyles(
  {
    root: {
      width: '100vw',
      height: '100vh',

      background: theme.background,

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      overflow: 'hidden',

      position: 'relative',
    },

    image: {
      position: 'absolute',
      left: -10,
      bottom: -12,

      transformOrigin: 'center 500px',
      pointerEvents: 'none',
    },

    body: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    text: {
      color: 'white',
      fontWeight: 700,
      textAlign: 'center',
    },

    link: {
      color: 'white',
    },
  },
  { name: 'NotFondPage' }
);
